import { handleError } from 'handleError';
import store, { AppThunk } from '../store';
import {
  getSubscription as getSubscriptionApi,
  getCreditUsage as getCreditUsageApi,
} from '../../api/subscription.api';
import {
  getSubscriptionStart,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  getCreditUsageSuccess,
} from '../reducers/subscription';

export const getSubscription = (orgId: number): AppThunk => async (
  dispatch
) => {
  const { subscription } = store.getState();
  if (subscription?.loading) {
    return;
  }
  dispatch(getSubscriptionStart());
  getSubscriptionApi(orgId)
    .then((data) => {
      dispatch(getSubscriptionSuccess(data.data));
    })
    .catch((err) => {
      dispatch(getSubscriptionFailure(err.message));
      err.stack = new Error().stack;
      handleError(err);
    });
};

export const getCreditUsage = (orgId: number): AppThunk => async (dispatch) => {
  getCreditUsageApi(orgId).then((data) => {
    dispatch(getCreditUsageSuccess(data.data));
  });
};
