import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { getCreditUsage } from 'store/actions/subscription.actions';
import { useLocation } from 'react-router-dom';
import Button from '../Shared/Button';
import './CreditUsage.scss';

const CreditUsage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: RootState) => state.profile);
  const credit_usage = useSelector(
    (state: RootState) => state.subscription?.credit_usage
  );
  const orgId = userDetails?.recruiter_detail?.organisation_id;

  useEffect(() => {
    if (orgId) {
      dispatch(getCreditUsage(orgId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, orgId]);

  const creditUsagePercentage = (): number => {
    if (credit_usage) {
      return (
        (credit_usage.subscription_credits_used /
          credit_usage.subscription_credits) *
        100
      );
    }
    return 0;
  };

  return (
    <div>
      {credit_usage && (
        <div className="credit-usage subscription main-container">
          <div className="details">
            <h3>Credits</h3>
            <div className="credits-used-section">
              <p className="credits-used-title">Subscription Credits Used</p>
              <p>
                <span
                  className={`${
                    credit_usage.subscription_credits_used >=
                    credit_usage.subscription_credits
                      ? 'danger-text'
                      : ''
                  } credit-number`}
                >
                  {credit_usage.subscription_credits_used.toLocaleString()}
                </span>{' '}
                of{' '}
                <span className="credit-number">
                  {credit_usage.subscription_credits.toLocaleString()}
                </span>
              </p>
            </div>
            <div className="progress-bar">
              <span
                className="progress-fill"
                style={{ width: `${creditUsagePercentage()}%` }}
              />
            </div>
            <p className="remaining-credits">
              {(
                credit_usage.subscription_credits -
                credit_usage.subscription_credits_used
              ).toLocaleString()}{' '}
              subscription credits remaining for the current period.
            </p>
            <p className="additional-credits-available">
              {credit_usage.remaining_additional_credits >= 0 ? (
                <div>
                  {credit_usage.remaining_additional_credits.toLocaleString()}{' '}
                  additional credits available.
                </div>
              ) : (
                <div className="danger-text">
                  You have exceeded your available credits by{' '}
                  {(-credit_usage.remaining_additional_credits).toLocaleString()}
                  .
                </div>
              )}
            </p>
          </div>
          <div className="action">
            <Button
              onClick={() => window.open(`https://www.alooba.com/credits/`)}
              text="Learn More About Credits"
              variant="primary md"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditUsage;
